import { useEffect } from "react";
import "./Home.css";
import {  useSession } from "@descope/react-sdk";
import { useNavigate } from "react-router-dom";


function Pending() {
  const { isAuthenticated, isSessionLoading } = useSession();
  const navigate = useNavigate();


  useEffect(() => {
    if (!isSessionLoading) {
				if (isAuthenticated) {
					navigate("/");
				}
    }
  }, [navigate, isSessionLoading, isAuthenticated]);

  return (
    <div className="App">
      <header className="App-header">
				{isSessionLoading && <div>Loading...</div>}
				{isAuthenticated && <div>On it...</div>}
				{!isSessionLoading && !isAuthenticated && <div>
					<div>
						Oh, We may had a glitch here
					</div>
					<br />
					<div>
						But don't worry! just go back to the browser tab you started the quiz from, and you will be redirected to the right page
					</div>
					</div>}
      </header>
    </div>
  );
}

export default Pending;
