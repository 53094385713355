/* eslint-disable no-console */
import "./Login.css";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Descope, useSession } from "@descope/react-sdk";
import { useSearchParams } from "react-router-dom";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flowId");

  const { isAuthenticated, isSessionLoading } = useSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate, isAuthenticated]);

  const onSuccess = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onError = useCallback(() => {
    setErrorMessage("Something went wrong");
  }, [setErrorMessage]);

  return (
    <div className="login-wrapper">
      {isSessionLoading && <div>Loading...</div>}
      {!isSessionLoading && (
        <Descope
          theme="dark"
          flowId={flowId || "sign-up-or-in"}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
};

export default Login;
