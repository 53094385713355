import { useCallback, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./Home.css";
import { useDescope, useSession, useUser } from "@descope/react-sdk";
import { useNavigate } from "react-router-dom";

const getUserDisplayName = (user) => {
  const username = user?.name || "";
  if (!username) {
    return "Hello!";
  }
  return `Hello ${username}!`;
};

function Home() {
  const { logout, refresh } = useDescope();
	const [refreshed, setRefreshed] = useState(false);
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user } = useUser();
  const navigate = useNavigate();

  const onLogout = useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (!isSessionLoading && !isAuthenticated) {
			if (!refreshed) {
				refresh()
					.then(() => {
						setRefreshed(true);
					})
			} else {
      	navigate("/login");
			}
    }
  }, [navigate, isSessionLoading, isAuthenticated, refreshed, refresh]);

  return (
    <div className="App">
      <header className="App-header">
				{isSessionLoading && <div>Loading...</div>}
        {isAuthenticated && (
          <>
						<img src={logo} className="App-logo" alt="logo" />
            <div className="username code"> {getUserDisplayName(user)}</div>
            {user && (
              <div className="phone-number code">
                {user.phone && <>Login phone number is {user.phone}</>}
              </div>
            )}
            <div className="logout-button">
              <button type="button" onClick={onLogout}>
                Logout
              </button>
            </div>
          </>
        )}
      </header>
    </div>
  );
}

export default Home;
